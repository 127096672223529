import {axios_instance as axios} from '@/axios-config.js';
import router from '@/router/index';

export default {
  namespaced: true,

  state: {
    token: null,
    user: {
      userName: null,
      fullName: null,
      roles: null
    },
    clientId: null,
  },

  getters: {
    authenticated(state) {
      return (state.token !== null) && (state.user !== null);
    },

    user(state) {
      return state.user;
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, { userName, fullName, roles}) {
      state.user = { userName, fullName, roles };
    },

    SET_CLIENT_ID(state, { clientId }) {
      state.clientId = clientId;
    },
  },

  actions: {
    async signIn({ commit, dispatch }, credentials) {
      try {
        this._vm.$Progress.start()
        let response = await axios.post('/v1/login_check', credentials);
        if(response.data.token){
          commit('SET_TOKEN', response.data.token);
          this._vm.$Progress.finish()
          dispatch('getMe');
        }
      } catch (e) {
        if (e.response.data.code === 401) {
          this._vm.$Progress.fail()
          commit('recovery/UPDATE_ERROR', {
            status: true,
            color: '#dc3545',
            error: 'Wpisałeś błędne hasło lub email lub nie masz dostępu do aplikacji',
          }, {root: true})
        }
      }
    },
    async switchUser({ commit, dispatch }) {
      try {
        this._vm.$Progress.start()
        let response = await axios.get(`/v1/switch-user/${router.history.current.params.user_uuid}/token/${router.history.current.params.user_token}`);
        if(response.data.token){
          commit('SET_TOKEN', response.data.token);
          this._vm.$Progress.finish()
          dispatch('getMe');
        }
      } catch (e) {
        if (e.response.data.code === 401) {
          this._vm.$Progress.fail()
          commit('recovery/UPDATE_ERROR', {
            status: true,
            color: '#dc3545',
            error: 'Wpisałeś błędne hasło lub email lub nie masz dostępu do aplikacji',
          }, {root: true})
        }
      }
    },
    async getMe( { commit, dispatch, }, type) {
      let response = await axios.get('/v1/account/accounts/me');
      commit('SET_USER', response.data);
      commit('SET_CLIENT_ID', response.data);

      if (type !== 'fromRouter') {
        dispatch('getFromApiDepartments', null, { root:true })
          .then(()=> {
            router.push({name: 'calendar'})
          })
          .catch(e=>console.error(e))
      }
    },

    checkTokenPresence( { commit, state} ) {
      if (state.token) {
        return
      } else if (localStorage.getItem('token')) {
        commit('SET_TOKEN', localStorage.getItem('token'));
      }
    }
  }
}
